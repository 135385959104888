import { checkboxClasses } from "@mui/material/Checkbox";
import { grey } from "@mui/material/colors";
import { alpha, createTheme } from "@mui/material/styles";
import {
  CheckedIcon,
  Icon,
  IndeterminateIcon,
} from "./components/common/checkbox-icons";

const mode = "light";

export const typographyVariants = [
  "gableh1",
  "gableh2",
  "gableh3",
  "gableh4",
  "gablesubtitle",
  "gablesubtitle2",
  "gablesubtitle3",
  "gablesubtitle4",
  "gablesubtitle5",
  "gablenav",
  "gablep",
  "gableapph1",
  "gableappformlabel",
  "gableappformgrouplabel",
  "gableapph2",
  "gableapph2small",
  "gableapph3",
  "gablesubheader",
  "gable16charocalgrey",
  "gable12steelgrey",
  "gable56DarkBlueGrey",
  "gable42DarkBlueGrey",
] as const;

export type GableVariant = (typeof typographyVariants)[number];
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    default: true;
  }
}
declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    ["multiline"]: true;
  }
}
declare module "@mui/material/styles/createTypography" {
  interface TypographyOptions
    extends Partial<
      Record<GableVariant, TypographyStyleOptions> & FontStyleOptions
    > {}

  interface Typography
    extends Record<GableVariant, TypographyStyle>,
      FontStyle,
      TypographyUtils {}
}
declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions {
    electricPurple?: string;
    melon?: string;
    macaroniAndCheese?: string;
    veryLightPink?: string;
    darkBlueGrey?: string;
    charcoalGrey?: string;
    coolGrey?: string;
    veryLightPinkTwo?: string;
    iceBlue?: string;
    paleViolet?: string;
    offWhite?: string;
    offWhite2?: string;
    paleLavender?: string;
    paleSalmon?: string;
    paleGrey?: string;
    steelGrey?: string;
    paleLilac?: string;
    lightTan?: string;
    lightLavender?: string;
    white?: string;
    extraLightGray?: string;
    lightBeige?: string;
    veryLightBeige?: string;
    palePeach?: string;
    dustyRose?: string;
    red?: string;
    pinkishSand?: string;
    yellowSea?: string;
    fuchsiaPink?: string;
    default?: {
      main: string;
      dark: string;
    };
  }

  type GablePalette = typeof palette;

  interface Palette extends GablePalette {}
}

export const palette = {
  aliceBlue: "#f0f7fc",
  electricPurple: "#8d2df2",
  melon: "#ec7965",
  macaroniAndCheese: "#f3bf2d",
  yellowSea: "#F3A239",
  fuchsiaPink: "#B850B4",
  veryLightPink: "#f7f2ef",
  darkBlueGrey: "#112b3c",
  charcoalGrey: "#3e484e",
  coolGrey: "#a2a8ad",
  veryLightPinkTwo: "#fbe7e3",
  iceBlue: "#e8eff4",
  paleViolet: "#d1abfa",
  offWhite: "#fdf7e5",
  offWhite2: "#F9F9F9",
  paleLavender: "#f1e5fd",
  paleSalmon: "#f5bcb2",
  paleGrey: "#f8f3ff",
  steelGrey: "#74828b",
  paleLilac: "#e2dfe7",
  lightTan: "#fae5ab",
  lightLavender: "#e1c6fe",
  white: "#ffffff",
  extraLightGray: "#0000003A",
  lightBeige: "#f5c4c4",
  veryLightBeige: "#FDF8F5",
  palePeach: "#FCF1EF",
  dustyRose: "#fbd1c9",
  red: "#FF3F5E",
  pinkishSand: "#F4F0EA",
  parisGreen: "#64D384",
  barbiePink: "#D94BE5",
} as const;

export type GableColor = keyof typeof palette;

const base = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1366,
      xl: 1920,
    },
  },
});

const customPalette = createTheme({
  palette: {
    mode,
    action: {
      hover: "rgba(0, 0, 0, 0.04)",
    },
    background: {
      default: "#fff",
    },
    primary: {
      main: palette.electricPurple,
    },
    secondary: {
      main: palette.melon,
    },
    text: {
      primary: palette.darkBlueGrey,
      secondary: palette.charcoalGrey,
    },
    default: {
      main: grey[300],
      dark: grey[400],
    },
    ...palette,
  },
}).palette;

const theme = createTheme(
  {
    shape: {
      borderRadius: 28,
    },
    breakpoints: base.breakpoints,
    shadows: [
      "none",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
      "0 4px 18px 0 rgba(17, 43, 60, 0.1)",
    ],
    typography: {
      fontFamily: "'Poppins', sans-serif",
      button: {
        textTransform: "none",
        fontWeight: 600,
      },
      h3: {
        fontSize: 62,
        lineHeight: "70px",
        fontWeight: 300,
      },
      h6: {
        fontWeight: 400,
      },
      gableh1: {
        fontSize: 62,
        lineHeight: "72px",
        fontWeight: 200,
      },
      gableh2: {
        fontSize: 48,
        fontWeight: 200,
        lineHeight: 1.08,
        letterSpacing: "1.2px",
      },
      gableh3: {
        fontSize: 22,
        fontWeight: 600,
        lineHeight: 1.18,
        letterSpacing: "0.6px",
      },
      gablesubtitle: {
        fontSize: 20,
        fontWeight: 300,
        lineHeight: 1.6,
        letterSpacing: "0.8px",
      },
      gablesubtitle2: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: "26px",
        letterSpacing: "0.55px",
      },
      gablesubtitle3: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: "26px",
        letterSpacing: "0.55px",
      },
      gablesubtitle4: {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "0.55px",
      },
      gablesubtitle5: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "26px",
        letterSpacing: "0.6px",
      },
      gablep: {
        fontSize: 18,
        lineHeight: 1.56,
        letterSpacing: "0.8px",
      },
      gablesubheader: {
        fontSize: 16,
        lineHeight: 1.56,
        letterSpacing: "0.8px",
      },
      gableh4: {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: "1.44",
        letterSpacing: "0.6px",
      },
      gableapph1: {
        fontSize: 48,
        fontWeight: 300,
        letterSpacing: "1.5px",
        lineHeight: "56px",
      },
      gableapph2: {
        fontSize: 38,
        fontWeight: 300,
        letterSpacing: "0.8px",
        lineHeight: "42px",
      },
      gableapph2small: {
        fontSize: 32,
        fontWeight: 200,
        letterSpacing: "0.8px",
        lineHeight: "32px",
      },
      gableapph3: {
        fontSize: 26,
        fontWeight: 200,
        letterSpacing: "0.8px",
        lineHeight: "32px",
      },
      gableappformlabel: {
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: "0.4px",
        lineHeight: "22px",
      },
      gableappformgrouplabel: {
        fontSize: 22,
        fontWeight: 500,
        letterSpacing: "0.6px",
        lineHeight: "22px",
      },
      gable16charocalgrey: {
        fontSize: 16,
        color: palette.charcoalGrey,
        letterSpacing: "0.5px",
        lineHeight: "18px",
      },
      gable12steelgrey: {
        fontSize: 12,
        color: palette.steelGrey,
        letterSpacing: "0.5px",
        lineHeight: "14px",
      },
      gable56DarkBlueGrey: {
        fontSize: 56,
        fontWeight: 300,
        lineHeight: "60px",
        color: palette.darkBlueGrey,
        [base.breakpoints.down("lg")]: {
          fontSize: 42,
          lineHeight: "48px",
        },
        [base.breakpoints.down("md")]: {
          fontSize: 30,
          lineHeight: "34px",
        },
      },
      gable42DarkBlueGrey: {
        fontSize: 42,
        fontWeight: 300,
        lineHeight: "48px",
        color: palette.darkBlueGrey,
        [base.breakpoints.down("lg")]: {
          fontSize: 36,
          lineHeight: "40px",
        },
        [base.breakpoints.down("md")]: {
          fontSize: 28,
          lineHeight: "32px",
        },
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          // This was the default body style in MUI v4. It changed in V5, but we're keeping it for now.
          body: {
            fontSize: "0.875rem",
            lineHeight: 1.43,
            letterSpacing: "0.01071em",
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        // The 'default' color was removed in MUI v5. This list of variants is how we get it back.
        // Based on a code sample from https://mui.com/material-ui/migration/v5-component-changes/#%E2%9C%85-remove-default-color-prop
        variants: [
          {
            props: { variant: "contained", color: "default" },
            style: {
              color: customPalette.getContrastText(customPalette.grey[300]),
            },
          },
          {
            props: { variant: "outlined", color: "default" },
            style: {
              color: customPalette.text.primary,
              borderColor:
                customPalette.mode === "light"
                  ? "rgba(0, 0, 0, 0.23)"
                  : "rgba(255, 255, 255, 0.23)",
              "&.Mui-disabled": {
                border: `1px solid ${customPalette.action.disabledBackground}`,
              },
              "&:hover": {
                borderColor:
                  customPalette.mode === "light"
                    ? "rgba(0, 0, 0, 0.23)"
                    : "rgba(255, 255, 255, 0.23)",
                backgroundColor: alpha(
                  customPalette.text.primary,
                  customPalette.action.hoverOpacity
                ),
              },
            },
          },
          {
            props: { variant: "text", color: "default" },
            style: {
              color: customPalette.text.primary,
              "&:hover": {
                backgroundColor: alpha(
                  customPalette.text.primary,
                  customPalette.action.hoverOpacity
                ),
              },
            },
          },
        ],
        defaultProps: {
          color: "default",
        },
        styleOverrides: {
          root: {
            borderRadius: 21,
            fontSize: 16,
            boxShadow: "none",
            whiteSpace: "nowrap",
          },
          outlined: {
            padding: "6px 28px",
          },
          contained: {
            padding: "7px 30px",
          },
          sizeLarge: {
            borderRadius: 30,
          },
          containedSizeLarge: {
            padding: "12px 42px 12px 42px",
            fontSize: 20,
            [base.breakpoints.down("md")]: {
              fontSize: 18,
            },
          },
          outlinedSizeLarge: {
            padding: "12px 42px 12px 42px",
            fontSize: 20,
            [base.breakpoints.down("md")]: {
              fontSize: 18,
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: 16,
            "&.Mui-selected": {
              fontWeight: 600,
              backgroundColor: palette.electricPurple,
              color: "#fff",
              "&:hover": {
                backgroundColor: palette.electricPurple,
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 16,
            [base.breakpoints.down("md")]: {
              minHeight: 52,
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: palette.darkBlueGrey,
            fontSize: 12,
            borderRadius: 8,
          },
          arrow: {
            color: palette.darkBlueGrey,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(255,255,255,0.92)",
            // There's a bug in ReactTransitionGroup that causes the backdrop to remain open even when opacity is 0.
            // See MUI issue: https://github.com/mui/material-ui/issues/32286
            '&[style*="opacity: 0"]': {
              pointerEvents: "none",
            },
          },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            backgroundColor: palette.veryLightPink,
          },
          text: {
            transform: "none",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          body: {
            color: palette.steelGrey,
            fontSize: 16,
          },
          head: {
            color: palette.charcoalGrey,
            fontSize: 14,
            fontWeight: 700,
            letterSpacing: "0.4px",
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          displayedRows: {
            color: palette.charcoalGrey,
            fontWeight: 500,
          },
          actions: {
            color: palette.charcoalGrey,
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiRadio: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: "primary",
          icon: <Icon />,
          indeterminateIcon: <IndeterminateIcon />,
          checkedIcon: <CheckedIcon />,
          sx: {
            padding: "11px",
          },
        },
        styleOverrides: {
          root: {
            [`&.${checkboxClasses.disabled}`]: {
              opacity: 0.5,
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: "outlined",
          MenuProps: {
            PaperProps: {
              variant: "elevation",
            },
            style: { maxHeight: 360 },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          },
        },
      },
      MuiPopover: {
        defaultProps: {
          slotProps: {
            root: {
              slotProps: {
                backdrop: {
                  style: {
                    backgroundColor: "transparent",
                  },
                },
              },
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            lineHeight: 1.75,
            "&.Mui-selected": {
              fontWeight: 600,
            },
          },
        },
      },
      MuiPopper: {
        defaultProps: {
          slotProps: {
            root: {
              style: {
                // MUI's default zIndex for Popovers is 1300. Since we often use the underlying Popper component for better customization,
                // we increase the zIndex to ensure that the Popper behaves similarly to Popover.
                zIndex: base.zIndex.modal,
              },
            },
          },
        },
      },
      MuiChip: {
        variants: [
          {
            props: { variant: "multiline" },
            style: {
              background: "none",
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
              },
            },
          },
        ],
      },
    },
  },
  { palette: customPalette }
);

theme.typography.h3 = {
  ...theme.typography.h3,
  [theme.breakpoints.down("sm")]: {
    fontSize: 36,
    lineHeight: "42px",
  },
};

theme.typography.gableh1 = {
  ...theme.typography.gableh1,
  [theme.breakpoints.down("lg")]: {
    fontSize: 50,
    lineHeight: "60px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 38,
    lineHeight: "40px",
  },
};

theme.typography.gableapph1 = {
  ...theme.typography.gableapph1,
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
    lineHeight: "28px",
  },
};

theme.typography.gableapph2small = {
  ...theme.typography.gableapph2small,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
    lineHeight: "18px",
  },
};

theme.typography.gablep = {
  ...theme.typography.gablep,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    lineHeight: 1.48,
  },
};

theme.typography.gableh2 = {
  ...theme.typography.gableh2,
  [theme.breakpoints.down("sm")]: {
    fontSize: 28,
    lineHeight: "32px",
  },
};

theme.typography.gableh3 = {
  ...theme.typography.gableh3,
  [theme.breakpoints.down("sm")]: {
    fontSize: 18,
    lineHeight: "22px",
  },
};

theme.typography.gablesubtitle = {
  ...theme.typography.gablesubtitle,
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    lineHeight: "24px",
  },
};

export default theme;
