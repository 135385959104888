import {
  addDays,
  endOfWeek,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
  isSameMonth,
  isValid,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
} from "date-fns";
import { formatDateForFilters } from "src/components/common/utils";
import { DateAggregationUnit } from "src/constants";
import { assertUnreachable } from "src/types/utils";

export enum DatePreset {
  Today = "Today",
  Yesterday = "Yesterday",
  Tomorrow = "Tomorrow",
  "This Week" = "This Week",
  "7D" = "7D",
  "30D" = "30D",
  MTD = "MTD",
  QTD = "QTD",
  YTD = "YTD",
}

export const DEFAULT_DATE_PRESETS = [
  DatePreset.Yesterday,
  DatePreset["7D"],
  DatePreset["30D"],
  DatePreset.MTD,
  DatePreset.QTD,
  DatePreset.YTD,
];

export const datePresetOptions: Record<
  DatePreset,
  () => { startDate: Date; endDate: Date; description?: string }
> = {
  [DatePreset.Today]: () => {
    const today = new Date();
    return {
      startDate: today,
      endDate: today,
    };
  },
  [DatePreset.Yesterday]: () => {
    const today = new Date();
    return {
      startDate: subDays(today, 1),
      endDate: subDays(today, 1),
    };
  },
  [DatePreset.Tomorrow]: () => {
    const today = new Date();
    return {
      startDate: addDays(today, 1),
      endDate: addDays(today, 1),
    };
  },
  [DatePreset["This Week"]]: () => {
    const today = new Date();
    return {
      startDate: startOfWeek(today),
      endDate: endOfWeek(today),
    };
  },
  [DatePreset["7D"]]: () => {
    const today = new Date();
    return {
      startDate: subDays(today, 7),
      endDate: today,
      description: "Last 7 days",
    };
  },
  [DatePreset["30D"]]: () => {
    const today = new Date();
    return {
      startDate: subDays(today, 30),
      endDate: today,
      description: "Last 30 days",
    };
  },
  [DatePreset.MTD]: () => {
    const today = new Date();
    return {
      startDate: startOfMonth(today),
      endDate: today,
      description: "From the beginning of the month",
    };
  },
  [DatePreset.QTD]: () => {
    const today = new Date();
    return {
      startDate: startOfQuarter(today),
      endDate: today,
      description: "From the beginning of the quarter",
    };
  },
  [DatePreset.YTD]: () => {
    const today = new Date();
    return {
      startDate: startOfYear(today),
      endDate: today,
      description: "From the beginning of the year",
    };
  },
};

export const isDateRangeMonthlySelection = (
  startDate: Date | undefined,
  endDate: Date | undefined
) => {
  return (
    isValid(startDate) &&
    isValid(endDate) &&
    isFirstDayOfMonth(startDate) &&
    isLastDayOfMonth(endDate) &&
    isSameMonth(startDate, endDate)
  );
};

export const getMatchingDatePresets = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  options: DatePreset[] = []
) => {
  if (!isValid(startDate) || !isValid(endDate) || !options.length) {
    return [];
  }

  return options.reduce((acc, preset) => {
    const range = datePresetOptions[preset]?.();
    if (
      isSameDay(range?.startDate, startDate) &&
      isSameDay(range?.endDate, endDate)
    ) {
      acc.push(preset);
    }
    return acc;
  }, [] as DatePreset[]);
};

/**
 * Converts a string to a date object, ignoring the time part.
 *
 * This should be equivalent to `new Date(year, month, day)`, which creates the date in the local timezone.
 */
export function dateISOToStartOfDay(date: string) {
  // The types shouldn't allow it, but in case we actually got a date object, just return it
  if ((date as unknown) instanceof Date) {
    console.warn(
      "dateISOToStartOfDay was called with a Date object. This is probably a bug."
    );
    return startOfDay(date as unknown as Date);
  }
  const orig = new Date(date);
  return new Date(orig.getUTCFullYear(), orig.getUTCMonth(), orig.getUTCDate());
}

export const getKeyByDateAggregationUnit = (
  date: string,
  dateBucketUnit: DateAggregationUnit
) => {
  const dateObj = dateISOToStartOfDay(date);

  switch (dateBucketUnit) {
    case DateAggregationUnit.Day:
      return formatDateForFilters(dateObj);
    case DateAggregationUnit.Week:
      return `${formatDateForFilters(startOfWeek(dateObj))}`;
    case DateAggregationUnit.Month:
      return `${formatDateForFilters(startOfMonth(dateObj))}`;
    case DateAggregationUnit.Quarter:
      return `${formatDateForFilters(startOfQuarter(dateObj))}`;
    default:
      assertUnreachable(dateBucketUnit);
  }
};
