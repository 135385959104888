import { Theme, useMediaQuery, useTheme } from "@mui/material";
import {
  Day,
  addDays,
  format,
  isSameMonth,
  isSameWeek,
  isSameYear,
  startOfDay,
  startOfWeek,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { isEqual, pick } from "lodash";
import { IWorkingHours } from "src/apis/spaces";
import { mobileUserAgentRegex } from "src/mobile-agent-regex";
import { dateISOToStartOfDay } from "src/utils/dates";
import { z } from "zod";
import type { Booking, InvitedGuest } from "../../apis/bookings";
import { DateFormats, Hours } from "../../constants";
import { SlimPublicUser, User } from "../../types/auth-types";
import { generateCloudinaryImageUrl } from "./cloudinary";

export const getUserImage = (
  user: Partial<
    Pick<
      User,
      | "chosenProfilePic"
      | "uploadedProfilePic"
      | "facebookProfilePic"
      | "linkedinProfilePic"
    >
  >
) => {
  return (
    user?.chosenProfilePic ||
    user?.uploadedProfilePic ||
    user?.facebookProfilePic ||
    user?.linkedinProfilePic
  );
};

export const getCloudinaryUserAvatar = ({
  user,
  width = 42,
}: {
  user: Parameters<typeof getUserImage>[0];
  width?: number;
}) => {
  return generateCloudinaryImageUrl(getUserImage(user), {
    width,
    height: width,
    gravity: "face",
    crop: "fill",
    radius: "max",
    dpr: 2,
  });
};

export const getRemainingSeatsFromBooking = (
  booking: Pick<Booking, "seats"> & {
    invitedGuests: (Omit<InvitedGuest, "user"> & { user: SlimPublicUser })[];
  }
) => {
  // Backend and mobile might allow one extra user to join a meeting room company booking - which could cause the calculation to return -1.  Ensuring that 0 is returned regardless.
  const remainingSeats =
    booking.seats - ((booking.invitedGuests?.length || 0) + 1);
  return remainingSeats >= 0 ? remainingSeats : 0;
};

export const formatDateForFilters = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const calculateMinimumDateForFilters = (timeZoneId: string) => {
  const now = new Date();
  const currentTimeInTimeZone = utcToZonedTime(now, timeZoneId);
  return startOfDay(currentTimeInTimeZone);
};

export const isMobileDevice = () => {
  if (typeof navigator === "undefined") return false;
  return Boolean(navigator?.userAgent?.match(mobileUserAgentRegex));
};

export const useIsMobileWeb = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return isSmallScreen;
};

export const useIsMobileWebOnPhone = () =>
  useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

export const formatDay = (day: Day, dateFormat?: DateFormats) => {
  return format(addDays(startOfWeek(new Date()), day), dateFormat ?? "E");
};

export const formatFullDayName = (day: Day) => {
  return format(
    addDays(startOfWeek(new Date()), day),
    DateFormats.FULL_DAY_NAME
  );
};

export const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const numberFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const percentageFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const roundedPercentageFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const getBookingStartAndEnd = (
  booking: Pick<Booking, "date" | "startHour" | "endHour" | "space">
) => {
  const day = dateISOToStartOfDay(booking.date).getDay() as Day;
  if (booking.startHour && booking.endHour) {
    return {
      day,
      startHour: booking.startHour as (typeof Hours)[number],
      endHour: booking.endHour as (typeof Hours)[number],
    };
  }
  return (
    booking.space.workingHours.find((wh) => wh.day === day) ?? {
      day,
      startHour: "9:00AM" as (typeof Hours)[number],
      endHour: "6:00PM" as (typeof Hours)[number],
    }
  );
};

export function getDateRangeTexts(
  startDate: Date,
  endDate: Date,
  formats = {
    diffYear: "d LLL yyy",
    diffMonth: "d LLL",
    diffWeek: "d LLL",
    diffDay: "d LLL",
  }
) {
  if (!startDate || !endDate) {
    return { startDate: null, endDate: null };
  }
  let relevantFormat: string;
  if (!isSameYear(startDate, endDate)) {
    relevantFormat = formats.diffYear;
  } else if (!isSameMonth(startDate, endDate)) {
    relevantFormat = formats.diffMonth;
  } else if (!isSameWeek(startDate, endDate)) {
    relevantFormat = formats.diffWeek;
  } else {
    relevantFormat = formats.diffDay;
  }
  return {
    startDate: format(startDate, relevantFormat),
    endDate: format(endDate, relevantFormat),
  };
}

export const formatMultiDate = (dates: Date[]) => {
  if (!dates.length) {
    return "";
  }
  if (dates.length === 1) {
    return format(dates[0], DateFormats.EEE_COMMA_D_MMM);
  }

  return dates.map((d) => format(d, DateFormats.EEE_D_MMM)).join(", ");
};

const emailSchema = z.string().email();

export function isValidEmail(email: string) {
  return emailSchema.safeParse(email).success;
}

export const convertMilesToKilometers = (miles: number) => miles * 1.60934;

export function itemCountFormatter(props: {
  count: number;
  singular: string;
  plural: string;
  asObject: true;
}): { text: string };
export function itemCountFormatter(props: {
  count: number;
  singular: string;
  plural: string;
  asObject?: false;
  numberFormatter?: (count: number) => string;
}): string;
export function itemCountFormatter(props: {
  count: number;
  singular: string;
  plural: string;
  asObject?: boolean;
  numberFormatter?: (count: number) => string;
}) {
  const { count, singular, plural, numberFormatter, asObject = false } = props;
  const text = count === 1 ? singular : plural;

  return asObject
    ? { text }
    : `${numberFormatter ? numberFormatter(count) : count} ${text}`;
}

export const workingHoursAreValid = (
  startHour: (typeof Hours)[number],
  endHour: (typeof Hours)[number]
) => Hours.indexOf(startHour) <= Hours.indexOf(endHour) - 8;

export const workingHoursAreEqual = (
  a: IWorkingHours[],
  b: IWorkingHours[]
) => {
  return isEqual(
    a.map((wh) => pick(wh, ["startHour", "endHour", "day"])),
    b.map((wh) => pick(wh, ["startHour", "endHour", "day"]))
  );
};
